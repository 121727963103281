import FluroContent from "./fluro-content";

class Organisation extends FluroContent {
    constructor() {
        super("organisation");
    }
}

const _Organisation = new Organisation();
export default _Organisation;
