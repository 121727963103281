<template>
    <div>
        <v-container px-8 v-if="error" class="dark--text">
            <v-row>
                <v-col cols="12" class="shrink">
                    <h2 class="text-no-wrap text-center my-6">Log In</h2>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p v-text="error"></p>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn block large color="primary" @click="clearError">Try Again</v-btn>
                </v-col>
            </v-row>
        </v-container>

        <v-container px-8 v-else class="dark--text">
            <form @submit.prevent="submit">
                <v-row>
                    <v-col cols="12" class="shrink">
                        <h2 @click="add" class="text-no-wrap text-center my-6">Log In</h2>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col v-if="secretList >= 10" cols="12">
                        <v-select v-model="selectedOrg" :items="orgs" label="Organisation">
                            <template #item="{ item }">
                                <v-list-item-avatar>
                                    <v-img :src="item.image" width="32" height="32"></v-img>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <p class="x-small--text text-uppercase"><b>Email Address</b></p>
                        <v-text-field
                            ref="email"
                            v-model="email"
                            type="email"
                            class="field"
                            :disabled="loading"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-email-outline"
                            label=""
                            placeholder="Please enter your email..."
                            required
                            :rules="[rules.required, rules.email]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <p class="x-small--text text-uppercase"><b>Password</b></p>
                        <v-text-field
                            ref="password"
                            v-model="password"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                            @click:append="showPassword = !showPassword"
                            class="field"
                            dense
                            filled
                            solo
                            flat
                            prepend-inner-icon="mdi-lock-outline"
                            label=""
                            :disabled="loading"
                            placeholder="Please enter your password..."
                            required
                            :rules="[rules.required]"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn block large color="primary" type="submit" :loading="loading" :disabled="disabled"
                            >Log in</v-btn
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-btn
                            block
                            large
                            color="dark"
                            outlined
                            type="submit"
                            :disabled="loading"
                            :to="{ name: 'auth.signup' }"
                            >Sign up</v-btn
                        >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" align="center" justify="center">
                        <v-btn :to="{ name: 'auth.forgot' }" small text color="dark" :disabled="loading">
                            <span class="text-capitalize font-weight-regular"><b>Forgotten your password?</b></span>
                        </v-btn>
                    </v-col>
                </v-row>
            </form>
        </v-container>
    </div>
</template>

<script>
import _ from "lodash";
import UserMixin from "@/mixins/user-mixin";
import Organisation from "@/modules/app-module/organisation.js";
import { async } from "q";
export default {
    name: "login",

    mixins: [UserMixin],

    meta: {
        title: "Login",
    },

    data() {
        return {
            secretList: 0,
            selectedOrg: null,
            password: "",
            showPassword: false,
            error: "",
            loading: false,
            disabled: false,
            orgs: [],
            rules: {
                required: (value) => !!value || "This field is required",
                email: (value) => {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail";
                },
            },
        };
    },

    methods: {
        //method to add to the secret list, clicking this 10 times will reveal the dropdown
        add() {
            this.secretList++;
        },
        populateImage(item) {
            const image = this.$fluro.asset.imageUrl(item);
            return image;
        },
        async getOrgs() {
            const response = await Organisation.list().then(({ data }) => data);
            console.log("ORG LIST", this.orgs);
            response.forEach((element) => {
                const text = `${element.title}`;
                const value = element._id;
                const image = this.populateImage(element.data.logoLight._id);
                const item = { text: text, value: value, image: image };
                this.orgs.push(item);
            });
        },
        clearError() {
            this.error = false;
            this.$app.error = {};
        },
        async init() {
            await this.getOrgs();
        },
        submit() {
            //Get current org
            const org = this.$route.query.org;

            // Commit the 'setOrg' mutation to update the Vuex store state
            if (org) {
                this.$store.commit("org/setOrg", org);
            }
            //Check if there is an application
            var applicationContext = this.application && this.application.authenticationStyle == "application";
            this.loading = true;

            this.$fluro.auth
                .login(
                    {
                        username: this.email,
                        password: this.password,
                    },
                    {
                        bypassInterceptor: true,
                        application: applicationContext, //Sign in to the application rather than Fluro
                    }
                )
                .then(
                    async (res) => {
                        var user = res.data;
                        console.log("Login success", user);
                        this.$app.error = {};

                        this.$router
                            .replace({
                                name: "auth.welcome",
                            })
                            .catch(() => {});
                    },
                    (err) => {
                        var message = this.$fluro.utils.errorMessage(err);

                        this.error = "An error occured while trying to log you in. Please try again.";

                        console.log("ERROR!", message, err);

                        this.loading = false;
                        this.password = "";
                    }
                );
        },
    },

    computed: {
        email: {
            set(email) {
                this.$store.commit("email", email);
            },
            get(email) {
                return this.$store.getters.email;
            },
        },
    },
    watch: {
        selectedOrg(newV) {
            console.log("New Org", newV);
            this.$router
                .push({ path: "/auth/login", query: { org: newV } })
                .catch((err) => {})
                .finally(() => {
                    window.location.reload(true);
                });
        },
    },
    async mounted() {
        await this.init();
    },
};
</script>
